var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.$hasPermissions(_vm.clientSession, ["ASSET_SUMMARY_REPORT"], 1)
      ? _c(
          "div",
          [
            _c("vue-headful", {
              attrs: { title: _vm.pageTitle + " - " + _vm.idToPeriod() },
            }),
            _c(
              "div",
              {
                staticClass: "has-text-centered has-background-primary",
                staticStyle: { "margin-bottom": "20px" },
              },
              [
                _c(
                  "h1",
                  {
                    staticClass: "is-size-6 has-text-white",
                    staticStyle: { padding: "5px 0px" },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.pageheading.toLocaleUpperCase() +
                          " - " +
                          _vm.idToPeriod()
                      )
                    ),
                  ]
                ),
              ]
            ),
            _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
              _vm.isDownloading || _vm.isRegenerating
                ? _c(
                    "div",
                    { attrs: { id: "body-content-area" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            display: "flex",
                            "justify-content": "center",
                            "align-items": "center",
                            "flex-direction": "column",
                          },
                        },
                        [
                          _c("h1", { staticClass: "title is-4" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isDownloading
                                    ? "Downloading"
                                    : "Regenerating"
                                ) +
                                " Report" +
                                _vm._s(
                                  _vm.selectedRows.length > 1 ||
                                    _vm.selectedRows.length === 0
                                    ? "s"
                                    : ""
                                ) +
                                ". This may take a moment. "
                            ),
                          ]),
                          _c("p", { staticStyle: { "margin-top": "-1rem" } }, [
                            _vm._v(
                              " Please do not close this window or navigate away from this page. "
                            ),
                          ]),
                        ]
                      ),
                      _c("Loading"),
                    ],
                    1
                  )
                : _c("div", { attrs: { id: "body-content-area" } }, [
                    _c(
                      "div",
                      {
                        class: { hidden: !_vm.isLoading || !_vm.isDownloading },
                      },
                      [_c("spin-loader", { staticClass: "center-it" })],
                      1
                    ),
                    _c(
                      "div",
                      {
                        class: { visible: _vm.isLoading && !_vm.isDownloading },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "top-bar-spacer",
                            staticStyle: { height: "8px", width: "100%" },
                          },
                          [_vm._v(" ")]
                        ),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "control grid-button top-bar",
                              staticStyle: {
                                display: "flex",
                                "justify-content": "flex-end",
                                "margin-bottom": "1rem",
                              },
                            },
                            [
                              _c("div", { attrs: { id: "buttongroup" } }, [
                                _c("div", { staticClass: "level" }, [
                                  _c("div", { staticClass: "level-left" }, [
                                    _c(
                                      "div",
                                      { staticClass: "field is-grouped" },
                                      [
                                        _c("div", { staticClass: "control" }, [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "button is-dark",
                                              attrs: {
                                                disabled:
                                                  _vm.selectedRows.length === 0,
                                              },
                                              on: {
                                                click: _vm.openDeleteModal,
                                              },
                                            },
                                            [
                                              _vm._m(0),
                                              _c("span", [
                                                _vm._v(" Delete Selected "),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                        _c("div", { staticClass: "control" }, [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "button is-dark",
                                              attrs: {
                                                disabled:
                                                  _vm.selectedRows.length === 0,
                                              },
                                              on: {
                                                click: function (e) {
                                                  return _vm.regenerateSelected(
                                                    _vm.selectedReports,
                                                    _vm.idToPeriod(true),
                                                    "asset-summary-report",
                                                    this$1,
                                                    "isRegenerating",
                                                    function (_) {
                                                      _vm.deselect(
                                                        _vm.selectedReports,
                                                        "selected"
                                                      )
                                                      _vm.selectedRows = []
                                                      _vm.getAssertReports()
                                                    }
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "5px",
                                                    "margin-left": "-3px",
                                                  },
                                                },
                                                [
                                                  !_vm.isRegenerating
                                                    ? _c("i", {
                                                        staticClass:
                                                          "fas fa-redo",
                                                      })
                                                    : _c("i", {
                                                        staticClass:
                                                          "fas fa-spinner fa-spin",
                                                      }),
                                                ]
                                              ),
                                              !_vm.isRegenerating
                                                ? _c("span", [
                                                    _vm._v(
                                                      "Regenerate Selected"
                                                    ),
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      "Regenerating Selected"
                                                    ),
                                                  ]),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "control" },
                                          [
                                            _c("Select", {
                                              attrs: {
                                                buttonText:
                                                  _vm.selectedRows.length === 0
                                                    ? "Download All"
                                                    : "Download Selected",
                                                options: [
                                                  {
                                                    name: "Download as Excel",
                                                    value: "xlsx",
                                                    action: function () {
                                                      return _vm.selectedRows
                                                        .length === 0
                                                        ? _vm.downloadAll(
                                                            "xlsx"
                                                          )
                                                        : _vm.download("xlsx")
                                                    },
                                                  },
                                                  {
                                                    name: "Download as PDF",
                                                    value: "pdf",
                                                    action: function () {
                                                      return _vm.selectedRows
                                                        .length === 0
                                                        ? _vm.downloadAll("pdf")
                                                        : _vm.download("pdf")
                                                    },
                                                  },
                                                ],
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("div", { staticClass: "control" }, [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "button is-light",
                                              attrs: {
                                                disabled:
                                                  _vm.isLoadingAssetReports,
                                              },
                                              on: {
                                                click: _vm.getAssetReports,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "icon" },
                                                [
                                                  !_vm.isLoadingAssetReports
                                                    ? _c("i", {
                                                        staticClass:
                                                          "fas fa-sync-alt",
                                                      })
                                                    : _c("i", {
                                                        staticClass:
                                                          "fas fa-spinner fa-spin",
                                                      }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                        _c("div", [
                          _c(
                            "div",
                            { ref: "divWrapper" },
                            [
                              _vm.maxHeight > 0
                                ? _c("Grid", {
                                    ref: "reportGrid",
                                    style: { height: _vm.maxHeight + "px" },
                                    attrs: {
                                      filter: _vm.filter,
                                      "data-items": _vm.institutionReports,
                                      "selected-field": _vm.selectedField,
                                      sortable: true,
                                      sort: _vm.sort,
                                      filterable: true,
                                      pageable: Object.assign(
                                        {},
                                        _vm.pageable,
                                        {
                                          pageSizes: _vm.pageSizes.concat([
                                            "All",
                                          ]),
                                        }
                                      ),
                                      "page-size": _vm.pageSize,
                                      skip: _vm.pagination.skip,
                                      take: _vm.pagination.take,
                                      total: _vm.pagination.total,
                                      columns: _vm.columns,
                                    },
                                    on: {
                                      pagechange: _vm.pageChangeHandler,
                                      sortchange: _vm.sortChangeHandler,
                                      filterchange: _vm.filterChangeHandler,
                                      selectionchange: _vm.onSelectionChange,
                                      headerselectionchange:
                                        _vm.onHeaderSelectionChange,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "downloadTemplate",
                                          fn: function (ref) {
                                            var props = ref.props
                                            return [
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "has-text-centered",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            [
                                                              "PDF Complete",
                                                              "XLSX Complete",
                                                              "Both Complete",
                                                            ].includes(
                                                              props.dataItem
                                                                .status
                                                            ) ||
                                                            props.dataItem
                                                              .hasPDF,
                                                          expression:
                                                            "['PDF Complete', 'XLSX Complete', 'Both Complete'].includes(props.dataItem.status) || props.dataItem.hasPDF",
                                                        },
                                                      ],
                                                      staticClass: "download",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.managingReportId =
                                                            props.dataItem.id
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-download",
                                                      }),
                                                    ]
                                                  ),
                                                  _vm._v("   "),
                                                  [
                                                    "PDF Complete",
                                                    "Both Complete",
                                                  ].includes(
                                                    props.dataItem.status
                                                  ) || props.dataItem.hasPDF
                                                    ? _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "download",
                                                          attrs: { href: "#" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.$router.push(
                                                                {
                                                                  name: "ARSummaryBatchReportPreview",
                                                                  params: {
                                                                    reportId:
                                                                      props
                                                                        .dataItem
                                                                        .id,
                                                                    id: _vm
                                                                      .$route
                                                                      .params
                                                                      .id,
                                                                  },
                                                                }
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fas fa-eye",
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v("   "),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass: "download",
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.regenerate(
                                                            props.dataItem
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      !props.dataItem.isLoading
                                                        ? _c("i", {
                                                            staticClass:
                                                              "fas fa-redo",
                                                          })
                                                        : _c("i", {
                                                            staticClass:
                                                              "fas fa-spinner fa-spin",
                                                          }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "statusCell",
                                          fn: function (ref) {
                                            var props = ref.props
                                            return [
                                              _c("td", [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.dataItem.hasXLSX &&
                                                        props.dataItem.hasPDF
                                                        ? "Completed"
                                                        : props.dataItem.status
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                            ]
                                          },
                                        },
                                        {
                                          key: "statementDateTemplate",
                                          fn: function (ref) {
                                            var props = ref.props
                                            return [
                                              _c("td", [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatDate(
                                                        _vm
                                                          .moment(
                                                            props.dataItem
                                                              .statementDate
                                                          )
                                                          .endOf("month")
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                            ]
                                          },
                                        },
                                        {
                                          key: "creationDateTemplate",
                                          fn: function (ref) {
                                            var props = ref.props
                                            return [
                                              _c("td", [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatDate(
                                                        props.dataItem.createdAt
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                            ]
                                          },
                                        },
                                        {
                                          key: "batchNameCell",
                                          fn: function (ref) {
                                            var props = ref.props
                                            return [
                                              _c(
                                                "td",
                                                { staticClass: "editable" },
                                                [
                                                  _c(
                                                    "span",
                                                    [
                                                      _c(
                                                        "v-popover",
                                                        {
                                                          staticStyle: {
                                                            height: "100%",
                                                            width: "100%",
                                                            "text-overflow":
                                                              "clip!important",
                                                          },
                                                          attrs: {
                                                            open: props.dataItem
                                                              .showSaveDialog,
                                                            placement:
                                                              "right-end",
                                                            trigger: "manual",
                                                            autoHide: false,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                            },
                                                            [
                                                              _c("input", {
                                                                staticStyle: {
                                                                  height:
                                                                    "100%",
                                                                  width: "100%",
                                                                  "text-overflow":
                                                                    "clip!important",
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    props
                                                                      .dataItem
                                                                      .reportDisplayName,
                                                                },
                                                                on: {
                                                                  blur: function (
                                                                    $event
                                                                  ) {
                                                                    props.dataItem.showSaveDialog =
                                                                      $event
                                                                        .target
                                                                        .value !==
                                                                      props
                                                                        .dataItem
                                                                        .reportDisplayName
                                                                    props.dataItem.event =
                                                                      $event
                                                                    props.dataItem.reportDisplayName =
                                                                      $event.target.value
                                                                  },
                                                                  keyup: [
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        !$event.type.indexOf(
                                                                          "key"
                                                                        ) &&
                                                                        _vm._k(
                                                                          $event.keyCode,
                                                                          "esc",
                                                                          27,
                                                                          $event.key,
                                                                          [
                                                                            "Esc",
                                                                            "Escape",
                                                                          ]
                                                                        )
                                                                      ) {
                                                                        return null
                                                                      }
                                                                      _vm.reset(
                                                                        $event,
                                                                        props.dataItem
                                                                      )
                                                                      props.dataItem.event =
                                                                        null
                                                                      props.dataItem.showSaveDialog = false
                                                                    },
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        !$event.type.indexOf(
                                                                          "key"
                                                                        ) &&
                                                                        _vm._k(
                                                                          $event.keyCode,
                                                                          "enter",
                                                                          13,
                                                                          $event.key,
                                                                          "Enter"
                                                                        )
                                                                      ) {
                                                                        return null
                                                                      }
                                                                      props.dataItem.showSaveDialog =
                                                                        $event
                                                                          .target
                                                                          .value !==
                                                                        props
                                                                          .dataItem
                                                                          .reportDisplayName
                                                                      props.dataItem.event =
                                                                        $event
                                                                      props.dataItem.reportDisplayName =
                                                                        $event.target.value
                                                                    },
                                                                  ],
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                          _c(
                                                            "template",
                                                            {
                                                              attrs: {
                                                                id: "popover",
                                                              },
                                                              slot: "popover",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "popover-contain",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Are you sure? "
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "actions",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "button",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              cancel:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                _vm.reset(
                                                                                  $event,
                                                                                  props.dataItem
                                                                                )
                                                                                props.dataItem.event =
                                                                                  null
                                                                                props.dataItem.showSaveDialog = false
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Cancel"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "button",
                                                                        {
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                _vm.submitChanges(
                                                                                  props.dataItem,
                                                                                  "reportDisplayName"
                                                                                )
                                                                                props.dataItem.event =
                                                                                  null
                                                                                props.dataItem.showSaveDialog = false
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Save"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "headerCell",
                                          fn: function (ref) {
                                            var props = ref.props
                                            return [
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer!important",
                                                    "background-color":
                                                      "#fafafa!important",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return function () {
                                                        if (
                                                          props.dir ===
                                                          undefined
                                                        ) {
                                                          props.dir = "asc"
                                                        } else if (
                                                          props.dir === "asc"
                                                        ) {
                                                          props.dir = "desc"
                                                        } else {
                                                          props.dir = undefined
                                                        }

                                                        _vm.sortChangeHandler({
                                                          sort: [
                                                            {
                                                              field:
                                                                props.field,
                                                              dir: props.dir,
                                                            },
                                                          ],
                                                        })
                                                      }.apply(null, arguments)
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "javascript:void()",
                                                      },
                                                    },
                                                    [
                                                      props.dir === "asc" &&
                                                      _vm.sort.find(function (
                                                        _rec
                                                      ) {
                                                        return (
                                                          _rec.field ===
                                                          "batchName"
                                                        )
                                                      })
                                                        ? _c("span", {
                                                            staticClass:
                                                              "k-icon k-i-sort-asc-sm",
                                                          })
                                                        : _vm._e(),
                                                      props.dir === "desc" &&
                                                      _vm.sort.find(function (
                                                        _rec
                                                      ) {
                                                        return (
                                                          _rec.field ===
                                                          "batchName"
                                                        )
                                                      })
                                                        ? _c("span", {
                                                            staticClass:
                                                              "k-icon k-i-sort-desc-sm",
                                                          })
                                                        : _vm._e(),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "black",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(props.title)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "generationCell",
                                          fn: function (ref) {
                                            var props = ref.props
                                            return [
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      props.dataItem.stats
                                                        .generated
                                                    ) +
                                                    " / " +
                                                    _vm._s(
                                                      props.dataItem.stats
                                                        .scheduled
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                        {
                                          key: "reportTypeFilterSlotTemplate",
                                          fn: function (ref) {
                                            var props = ref.props
                                            var methods = ref.methods
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "k-filtercell" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "k-filtercell-wrapper",
                                                    },
                                                    [
                                                      _c("input", {
                                                        staticClass:
                                                          "k-textbox",
                                                        attrs: {
                                                          type: "text",
                                                          placeholder:
                                                            "Search File Name",
                                                          id: "" + props.field,
                                                        },
                                                        domProps: {
                                                          value:
                                                            props.value != ""
                                                              ? props.value
                                                              : null,
                                                        },
                                                        on: {
                                                          input: function (ev) {
                                                            methods.change({
                                                              operator:
                                                                "contains",
                                                              field:
                                                                props.field,
                                                              value:
                                                                ev.target.value,
                                                              syntheticEvent:
                                                                ev,
                                                            })
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "creationDateFilterCellTemplate",
                                          fn: function (ref) {
                                            var props = ref.props
                                            var methods = ref.methods
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "k-filtercell" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "k-filtercell-wrapper",
                                                    },
                                                    [
                                                      _c("input", {
                                                        staticClass:
                                                          "k-textbox",
                                                        attrs: {
                                                          type: "text",
                                                          placeholder:
                                                            "yyyy-mm-dd",
                                                          id: "" + props.field,
                                                        },
                                                        domProps: {
                                                          value:
                                                            props.value != ""
                                                              ? props.value
                                                              : null,
                                                        },
                                                        on: {
                                                          input: function (ev) {
                                                            methods.change({
                                                              operator:
                                                                "contains",
                                                              field:
                                                                props.field,
                                                              value:
                                                                ev.target.value,
                                                              syntheticEvent:
                                                                ev,
                                                            })
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2990198621
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _vm.showTypeSelectionModal
                          ? _c(
                              "div",
                              {
                                staticClass: "modal is-active",
                                attrs: { id: "select-type-modal" },
                              },
                              [
                                _c("div", {
                                  staticClass: "modal-background",
                                  on: {
                                    click: function ($event) {
                                      _vm.showTypeSelectionModal = false
                                      _vm.managingReportId = null
                                    },
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "modal-card modal-sm",
                                    staticStyle: { "max-width": "600px" },
                                  },
                                  [
                                    _c(
                                      "header",
                                      {
                                        staticClass:
                                          "modal-card-head has-bg-info",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "modal-card-title has-bg-info",
                                          },
                                          [_vm._v("Select Type")]
                                        ),
                                        _c("a", {
                                          staticClass: "delete",
                                          attrs: { "aria-label": "close" },
                                          on: {
                                            click: function ($event) {
                                              _vm.showTypeSelectionModal = false
                                              _vm.managingReportId = null
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "section",
                                      { staticClass: "modal-card-body" },
                                      [
                                        _c("div", { staticClass: "content" }, [
                                          _vm._v(
                                            " Please select the report format that you wish to download for: "
                                          ),
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.managingReport.reportName
                                              )
                                            ),
                                          ]),
                                          _vm._v(". "),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "footer",
                                      {
                                        staticClass: "modal-card-foot",
                                        staticStyle: {
                                          "justify-content": "space-between",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "button is-primary",
                                            staticStyle: { "flex-grow": "1" },
                                            attrs: {
                                              disabled:
                                                ![
                                                  "Completed",
                                                  "XLSX Complete",
                                                ].includes(
                                                  _vm.managingReport.status
                                                ) &&
                                                !_vm.managingReport.hasXLSX,
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.managingReport.hasXLSX ||
                                                [
                                                  "Completed",
                                                  "XLSX Complete",
                                                ].includes(
                                                  _vm.managingReport.status
                                                )
                                                  ? _vm.download(
                                                      "xlsx",
                                                      _vm.managingReportId
                                                    )
                                                  : null
                                              },
                                            },
                                          },
                                          [
                                            _vm._m(1),
                                            _c("span", [
                                              _vm._v("Download as Excel"),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "button is-primary",
                                            staticStyle: { "flex-grow": "1" },
                                            attrs: {
                                              disabled:
                                                ![
                                                  "Completed",
                                                  "PDF Complete",
                                                ].includes(
                                                  _vm.managingReport.status
                                                ) && !_vm.managingReport.hasPDF,
                                            },
                                            on: {
                                              click: function ($event) {
                                                ;[
                                                  "Completed",
                                                  "PDF Complete",
                                                ].includes(
                                                  _vm.managingReport.status
                                                ) || _vm.managingReport.hasPDF
                                                  ? _vm.download(
                                                      "pdf",
                                                      _vm.managingReportId
                                                    )
                                                  : null
                                              },
                                            },
                                          },
                                          [
                                            _vm._m(2),
                                            _c("span", [
                                              _vm._v("Download as PDF"),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "button",
                                            on: {
                                              click: function ($event) {
                                                _vm.showTypeSelectionModal = false
                                              },
                                            },
                                          },
                                          [_vm._v("Cancel")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.showDeleteModal
                          ? _c(
                              "div",
                              {
                                staticClass: "modal is-active",
                                attrs: { id: "delete-modal" },
                              },
                              [
                                _c("div", {
                                  staticClass: "modal-background",
                                  on: {
                                    click: function ($event) {
                                      _vm.showDeleteModal = false
                                    },
                                  },
                                }),
                                _c("div", { staticClass: "modal-card" }, [
                                  _c(
                                    "header",
                                    {
                                      staticClass:
                                        "modal-card-head has-bg-danger",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "modal-card-title has-bg-danger",
                                        },
                                        [_vm._v("Confirm Delete")]
                                      ),
                                      _c("a", {
                                        staticClass: "delete",
                                        attrs: { "aria-label": "close" },
                                        on: {
                                          click: function ($event) {
                                            _vm.showDeleteModal = false
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "section",
                                    { staticClass: "modal-card-body" },
                                    [
                                      _vm._m(3),
                                      _c("div", { staticClass: "field" }, [
                                        _c("div", { staticClass: "control" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "focus",
                                                rawName: "v-focus",
                                              },
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value: _vm.confirmationText,
                                                expression: "confirmationText",
                                                modifiers: { trim: true },
                                              },
                                            ],
                                            staticClass: "input",
                                            attrs: {
                                              type: "text",
                                              placeholder:
                                                "type `delete` and then click confirm",
                                            },
                                            domProps: {
                                              value: _vm.confirmationText,
                                            },
                                            on: {
                                              keydown: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                                return _vm.deleteIfConfirmed.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.confirmationText =
                                                  $event.target.value.trim()
                                              },
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "footer",
                                    { staticClass: "modal-card-foot" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "button is-danger",
                                          attrs: {
                                            disabled:
                                              _vm.confirmationText !== "delete",
                                          },
                                          on: { click: _vm.deleteIfConfirmed },
                                        },
                                        [
                                          _vm._m(4),
                                          _c("span", [_vm._v("Confirm")]),
                                        ]
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "button",
                                          on: {
                                            click: function ($event) {
                                              _vm.showDeleteModal = false
                                            },
                                          },
                                        },
                                        [_vm._v("Cancel")]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
            ]),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
            _vm._m(5),
            _c("center", [
              _c("b", [
                _vm._v("You Lack The Permissions Required To View This Page"),
              ]),
            ]),
          ],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-plus-square" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fa fa-download" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fa fa-download" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _vm._v(' Type "'),
      _c("b", [_vm._v("delete")]),
      _vm._v('" to confirm that you want to delete one or more items. '),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-trash-alt" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "has-text-centered has-background-primary",
        staticStyle: { "margin-bottom": "20px" },
      },
      [
        _c(
          "h1",
          {
            staticClass: "is-size-6 has-text-white",
            staticStyle: { padding: "5px 0px" },
          },
          [_vm._v("No Permission")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }